<template>
  <div class="outside">
    <!--        <van-nav-bar class="navbar" title="收房申请审核" fixed left-arrow @click-left="leftReturn"/>-->
    <rxNavBar title="收房申请审核" androidOrIOSFlag="true"></rxNavBar>
    <div class="head">
      <div class="search">
        <div class="search-icon" @click="goSearch"></div>
        <input type="text" placeholder="申请人、房源地址" v-model="searchContent" @keyup.enter="goSearch">
      </div>
      <div class="types">
        <span class="type" @click="isShow = !isShow" id="downList">{{ typeList[number] ? typeList[number].dictionaryTitle : '未审批' }}</span>
        <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
      </div>
<!--      &lt;!&ndash;下拉列表&ndash;&gt;-->
<!--      <div class="selectList" v-show="isShow">-->
<!--        <div class="select" :class="{selectActive:index==number}" @click="changeType(index)"-->
<!--             v-for="(item,index) in typeList" :key="index">-->
<!--          {{ item }}-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="blank-weight"></div>
    <div class="reviews">
      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" >
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="noContent ? '' : '已经到底了'"
          @load="this.onLoad"
      >
        <!--收房列表-->
        <div class="review" v-for="(item,index) in houseList" :key="index">
          <div
              :class="item.approvalStatusStr=='未审批'?'top-title-orange':item.approvalStatusStr=='已审批'?'top-title-green':'top-title-red'">
            {{ item.approvalStatusStr }}
          </div>
          <div class="position">{{ item.roomDetailedAddress }}</div>
          <div class="details">
            <div class="info">
              <div class="order">申请人</div>
              <div class="order-name">{{ item.staffName }}</div>
            </div>
            <div class="line"></div>
            <div class="info">
              <div class="order">租金</div>
              <div class="order-name">{{ item.rentMoney }}元</div>
            </div>
            <div class="line"></div>
            <div class="info">
              <div class="order">改造户型</div>
              <div class="order-name">{{ item.changHouseNum }}</div>
            </div>
          </div>
          <div class="bottom">
            <div class="time">{{ item.addTime }}</div>
            <div :class="item.approvalStatusStr==='未审批'?'check':'look'" @click="goTo(item)"
                 v-if="checkAuthList(authButtonsList,'approval')">
              {{ item.approvalStatusStr === '未审批' ? '审核' : '查看' }}
            </div>
          </div>
        </div>
        <common-empty v-if="noContent"></common-empty>
      </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="isShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="typeList"
          @cancel="isShow = false"
          :default-index="number"
          value-key="dictionaryTitle"
          @confirm="changeType"
      />
    </van-popup>

  </div>
</template>

<script>
import {
  NavBar, Toast, List, Picker,
  Popup,PullRefresh
} from 'vant';
import {getStaffId, responseUtil, checkAuthList} from '../../../libs/rongxunUtil';
import {
  reviewOfApplyForHouseList, queryBaseData, getStaffFuntionModelList
} from '../../../getData/getData';
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

export default {
  name: "reviewOfApplyForHouse",
  data() {
    return {
      noContent: false,
      refreshing:false,
      flag: false,
      searchContent: '',
      number: 1,
      type: '未审批',
      typeList: [{dictionaryValue: '',dictionaryTitle: '全部'}],
      loading: false,
      finished: false,
      count: 0,
      page: 1,
      datas: {
        numberPage: '3',
        approvalStatus: '1'
      },
      isShow: false,
      isWait: false,
      isAll: true,
      list: {
        content: ''
      },
      //模拟所有审批列表
      houseList: [],
      authButtonsList: []//权限列表

    }
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
      localStorage.removeItem('type');
      localStorage.removeItem('inputValue');
      localStorage.removeItem('currentLabelPublic');
    }
    next()
  },
  created() {
    this.initChooseTypes()
    this.getStaffFuntionModelList()
    let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
    if (currentLabel !== null) {
      if (currentLabel !== '') {
        this.number = Number(currentLabel);
      }
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]:PullRefresh
  },
  methods: {

    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.houseList = []
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },

    //获取权限方法
    getStaffFuntionModelList() {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'receiveRoom_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
          //console.log(that.authButtonsList)
        })
      })
    },
    checkAuthList,

    // //显示隐藏下拉菜单，监听div点击事件，实现点击空白处隐藏下拉列表
    // showOrHidden: function () {
    //   this.isShow = !this.isShow;
    //   var sp = document.getElementById("downList");
    //   if (sp) {
    //     document.addEventListener('click', e => {
    //       if (!sp.contains(e.target)) {
    //         this.isShow = false;
    //       }
    //     })
    //   }
    // },
    //搜索框查询
    goSearch() {
      //1.获取搜索内容
      //2.调用搜索接口，初始化数据列表
      this.changeType('',this.number);

    },

    onLoad: function () {
      var that = this
      let initData = that.datas
      that.datas.approvalStatus = that.number>0 ? that.number-1 : ''
      //debugger
      initData.currentPage = that.page + ''
      initData.staff_id = getStaffId()
      initData.searchName = that.searchContent
      //console.log(initData)
      reviewOfApplyForHouseList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount
          //console.log(that.count)
          let list = response.data.data.data
          for (let i = 0; i < list.length; i++) {
            that.houseList.push(list[i])
          }
          // 加载状态结束
          that.loading = false;
          if(that.refreshing){
            that.refreshing = false
          }
          // 数据全部加载完成
          if (that.houseList.length >= that.count) {
            that.finished = true;
          }
          if (1 == that.page && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
          }

          that.page++;
        })
      })
    },
    //初始化选择类型
    initChooseTypes: function () {
      let that = this
      let initData = {}
      initData.dbName = ['description']
      initData.fdName = ['APPROVESTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response.data.data.APPROVESTATUSMAP)
          let list = response.data.data.APPROVESTATUSMAP
          for (let i = 0; i < list.length; i++) {
            that.typeList.push(list[i])
          }
          // let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
          // if (currentLabel != null) {
          //   that.changeType(currentLabel);
          // }
        })
      })
    },
    //更改选择类型
    changeType: function (value,index) {
      //将选择的类型放入本地内存中，
      //console.log(index)
      if (index == '') {
        index = 0;
      }
      localStorage.setItem('currentLabel', JSON.stringify(index))
      this.houseList = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.isShow = false
      this.number = index;
      this.loading = true;
      this.noContent = false
      this.onLoad();
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    //按钮点击事件切换
    goTo: function (item) {
      if (item.approvalStatusStr == '未审批') {
        this.goToCheck(item)
      } else {
        this.goToDetails(item)
      }
    },
    //点击按钮去审批
    goToCheck(item) {
      this.$router.push({path: 'applyHouseCollection', query: {id: item.id}});
    },
    //点击按钮查看详情
    goToDetails(item) {
      this.$router.push({path: 'applicationForCollection', query: {id: item.id}});
    }
  }
}
</script>

<style scoped>
.outside {
  overflow: hidden;
  width: 100%;
}

.navbar {
  z-index: 10;
  background-color: #f8f8f8;
}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}

.search {
  margin: 10px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}

.types {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
}

.type {
  text-align: left;
  color: #fe5e3a;
  margin-left: 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.selectList {
  width: 100%;
  padding: 5px 0 14px 0;
  background-color: white;
}

.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}

.h-line {
  height: 1px;
  margin: 0 5px 0 10px;
  background-color: white;
}

.blank-weight {
  height: 93px;
}

.reviews {
  overflow: hidden;
  position: relative;
}

.review {
  margin: 0 15px 15px 15px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.top-title-orange {
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  background: linear-gradient(to right, #ffbe72, #ff6c41);
  color: white;
}

.top-title-green {
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  background: linear-gradient(to right, #63cb96, #30d0ac);
  color: white;
}

.top-title-red {
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  background: linear-gradient(to right, #fc401b, #e02321);
  color: white;
}

.position {
  margin: 15px 15px 0 15px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: black;
  font-weight: bold;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
}

.details {
  margin-top: 10px;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
}

.details .info {
  width: 114px;
  height: auto;
  text-align: center;
  margin-top: 10px;
}

.details .info .order {
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.details .info .order-name {
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #fe5e3a;
  margin-top: 10px;
  margin-bottom: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

.details .line {
  width: 1px;
  height: 40px;
  background-color: #f4f4f4;
  margin-top: 18px;
}

.bottom {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}

.bottom .time {
  width: 180px;
  height: 20px;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  color: #999999;
  margin-left: 15px;
}

.bottom .look {
  width: 90px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
  color: #fe5e3a;
  margin-left: 42px;
  border-radius: 8px;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(to right, #ffd59e, #ffa08a);
  background-clip: padding-box, border-box;
  background-origin: border-box;
}

.bottom .check {
  width: 90px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
  color: white;
  margin-left: 42px;
  border-radius: 8px;
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}

</style>
